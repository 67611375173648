import React, { useState, useEffect, useContext } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import api from '../services/api';
import { AuthContext } from '../context/AuthContext';

// Lista de países agrupados por idioma
const englishSpeakingCountries = ['US', 'CA', 'AU', 'GB', 'NZ', 'IE'];
const spanishSpeakingCountries = [
  'MX', 'CL', 'AR', 'UY', 'BO', 'CO', 'CR', 'CU', 'DO', 'EC',
  'ES', 'GT', 'GQ', 'HN', 'NI', 'PA', 'PY', 'PE', 'PR', 'SV', 'VE'
];
const portugueseSpeakingCountries = ['BR', 'PT'];

const SendEmail: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [recipients, setRecipients] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  // Estados para os filtros de idioma e país
  const [languageFilter, setLanguageFilter] = useState('');
  const [countryFilter, setCountryFilter] = useState('ALL');
  const [filteredCountries, setFilteredCountries] = useState<string[]>([]);

  // Ao selecionar o idioma, define os países correspondentes
  const handleLanguageChange = (language: string) => {
    setLanguageFilter(language);
    setCountryFilter('ALL'); // Reseta o filtro de país ao trocar o idioma

    if (language === 'en') {
      setFilteredCountries(englishSpeakingCountries);
    } else if (language === 'es') {
      setFilteredCountries(spanishSpeakingCountries);
    } else if (language === 'pt') {
      setFilteredCountries(portugueseSpeakingCountries);
    } else {
      setFilteredCountries([]);
    }
  };

  // Função que busca os usuários e filtra os e-mails conforme o idioma ou país selecionado
  const fetchAllUsersEmails = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/users/all');
      console.log(response.data)
      // Filtra os usuários com base no filtro de país ou idioma
      const emails = response.data
        .filter((user: { countryCode: string }) => {
          if (countryFilter !== 'ALL') {
            // Filtra por país específico
            return user.countryCode === countryFilter;
          }
          // Se país estiver como 'ALL', filtra com base no idioma
          if (languageFilter === 'en') {
            return englishSpeakingCountries.includes(user.countryCode);
          } else if (languageFilter === 'es') {
            return spanishSpeakingCountries.includes(user.countryCode);
          } else if (languageFilter === 'pt') {
            return portugueseSpeakingCountries.includes(user.countryCode);
          }
          // Se nenhum filtro estiver selecionado, retorna todos os usuários
          return true;
        })
        .map((user: { email: string }) => user.email)
        .join(', ');

      setRecipients(emails);
    } catch (err) {
      setError('Falha ao carregar e-mails dos usuários.');
    } finally {
      setLoading(false);
    }
  };

  // Função para enviar o e-mail para os destinatários selecionados
  const handleSendEmail = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await api.post('/email/send', {
        subject,
        body,
        recipients: recipients.split(',').map(email => email.trim()),
      });
      setSuccess(response.data.message);
      setSubject('');
      setBody('');
      setRecipients('');
    } catch (err) {
      setError('Falha ao enviar e-mail. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  // useEffect para carregar os e-mails automaticamente se o usuário for administrador
  useEffect(() => {
    if (user && ['kennyldoofc@gmail.com', 'kennildodev@gmail.com'].includes(user.email)) {
      fetchAllUsersEmails();
    }
  }, [user, countryFilter, languageFilter]);

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Enviar E-mails
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        {/* Filtro por idioma */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Idioma</InputLabel>
          <Select
            value={languageFilter}
            onChange={(e) => handleLanguageChange(e.target.value)}
          >
            <MenuItem value="pt">Português</MenuItem>
            <MenuItem value="es">Espanhol</MenuItem>
            <MenuItem value="en">Inglês</MenuItem>
          </Select>
        </FormControl>

        {/* Filtro por país, baseado no idioma selecionado */}
        {filteredCountries.length > 0 && (
          <FormControl fullWidth margin="normal">
            <InputLabel>País</InputLabel>
            <Select
              value={countryFilter}
              onChange={(e) => setCountryFilter(e.target.value)}
            >
              <MenuItem value="ALL">Todos os Países</MenuItem>
              {filteredCountries.map((countryCode) => (
                <MenuItem key={countryCode} value={countryCode}>
                  {countryCode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <TextField
          label="Assunto"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="Corpo do E-mail"
          value={body}
          onChange={(e) => setBody(e.target.value)}
          fullWidth
          multiline
          rows={6}
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="Destinatários (separados por vírgula)"
          value={recipients}
          onChange={(e) => setRecipients(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendEmail}
            disabled={loading || !subject || !body || !recipients}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : 'Enviar'}
          </Button>
        </Box>
      </Box>
      {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
    </Container>
  );
};

export default SendEmail;
